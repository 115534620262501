import styled from 'styled-components';
import { mediaQuery } from '../../../assets/theme';

const ContentsWrapper = styled.div`
  max-width: 80vw;
  margin: 0 auto;
  padding: ${({ $paddingTop = '64px' }) => $paddingTop} 0 64px 0;
  overflow-y: scroll;
  max-height: calc(100vh - 152px);

  ${mediaQuery.lessThan('lg')`
    max-height: calc(100vh - 200px);
  `};

  ${mediaQuery.lessThan('md')`
    max-width: 600px;
    padding: 8px 0;
    margin: 0 32px;
    `};

  ::-webkit-scrollbar {
    display: none;
  }
`;

export default ContentsWrapper;
