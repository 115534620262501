import axios from 'axios';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { colours } from '../assets/theme';
import Link from '../components/atoms/Link';
import { H2, P } from '../components/atoms/Text';
import ContentsWrapper from '../components/Layout/ContentsWrapper';
import Page from '../components/organisms/Page';
import { useLangStore } from '../store';
import { useTranslation } from '../hooks/TranslationHooks';

const ConfirmEmail = () => {
  const [pageState, setPageState] = useState('loading');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const urlParams = new URLSearchParams(window.location.search);
      const id = urlParams.get('id');
      const reset = urlParams.get('reset');

      const confirmEmail = async () => {
        try {
          const { status } = await axios.put(`https://cms.looseantenna.fm/subscribers/confirm?id=${id}&reset=${reset}`);
          if (status === 200) {
            setPageState('success');
          }
        } catch (err) {
          setPageState('invalid');
        }
      };

      if (!id || !reset) {
        setPageState('invalid');
      } else {
        confirmEmail();
      }
    }
  }, []);

  return (
    <Page title="Confirm your email address" noindex>
      <Contents>
        <PageInner pageState={pageState} />
      </Contents>
    </Page>
  );
};

const PageInner = ({ pageState }) => {
  const currentLang = useLangStore(store => store.currentLang);
  const successHeaderMessage = useTranslation({ key: 'confirmEmailSuccessHeader' })[currentLang];
  const successMessage = useTranslation({ key: 'confirmEmailSuccessMessage' })[currentLang];
  const invalidTokenHeaderMessage = useTranslation({ key: 'confirmEmailinvalidTokenHeader' })[currentLang];
  const invalidTokenMessage = useTranslation({ key: 'confirmEmailinvalidTokenMessage' })[currentLang];

  if (pageState === 'loading') {
    return null;
  }
  if (pageState === 'invalid') {
    return (
      <div>
        <Header align="center">Error: {invalidTokenHeaderMessage}</Header>
        <TextP align="center">{invalidTokenMessage}</TextP>
        <Link>
          <TextP align="center" colour={colours.blue}>
            → Go home
          </TextP>
        </Link>
      </div>
    );
  }
  if (pageState === 'success') {
    return (
      <div>
        <Header align="center">{successHeaderMessage}</Header>
        <TextP align="center">{successMessage}</TextP>
        <Link>
          <TextP align="center" colour={colours.blue}>
            → Go home
          </TextP>
        </Link>
      </div>
    );
  }
};

export const Header = styled(H2)`
  margin-bottom: 64px;
`;

export const TextP = styled(P)`
  margin: 32px 0;
`;

export const Contents = styled(ContentsWrapper)`
 height: calc(100vh - 215px);
 display: flex;
 align-items: center;
 justify-content: center
`;

export default ConfirmEmail;
